import React from "react";
import Routes from "./routes";
import "./styles/global.css";
import { createRoot } from "react-dom/client";

const root = createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Routes />
  </React.StrictMode>
);
