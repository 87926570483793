import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

export const DialogHeader = (props) => (
  <DialogTitle>
    {props.children}
    <IconButton
      aria-label="close"
      onClick={props.onClose}
      sx={{
        position: "absolute",
        right: 8,
        top: 8,
        color: (theme) => theme.palette.grey[500],
      }}
    >
      <CloseIcon />
    </IconButton>
  </DialogTitle>
);

export const AppDialog = (
  /**
   * @type {import('@mui/material/Dialog').DialogProps}
   */
  props
) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setIsModalOpen(props.open);
  }, [props.open]);

  return (
    <Dialog
      {...props}
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      onTransitionExited={() => {
        props.onTransitionExited?.();
        props.onClose();
      }}
    >
      <DialogHeader onClose={() => setIsModalOpen(false)}></DialogHeader>
      {props.children}
    </Dialog>
  );
};
