import { useMutation, useQuery } from "react-query";
import api from "../config/api";
import { useHistory } from "react-router-dom";
import axios, { AxiosError } from "axios";
import { getAuthHeaders, withAuthRedirect } from "../helpers/apiTokenHelpers";

export const useApiGetQueryFn = () => {
  const history = useHistory();

  return (
    /**
     *
     * @type {{
     *     map?: (res) => T;
     *     path: string;
     *     data: any;
     *     config?: import('axios').AxiosRequestConfig
     *
     * }}
     *
     */
    request
  ) =>
    withAuthRedirect(async () => {
      await request.prepare?.(request);

      return api
        .get(request.path, {
          headers: getAuthHeaders(),
          ...request.config,
        })
        .then((res) => (request.map ? request.map(res.data) : res.data));
    }, history);
};

/**
 *
 * @template T
 * @param {{
 *     map?: (res) => T;
 *     mapData: (res) => T;
 *     catch?: (error) => T;
 *     prepare?: ()=> void
 *     path: string;
 *     config?: import('axios').AxiosRequestConfig
 *     options?: import('react-query').UseQueryOptions;
 * }} request
 *
 *
 * @returns {import('react-query').UseQueryResult<T>}
 */
export const useApiGetQuery = (request) => {
  const history = useHistory();

  const queryFn = useApiGetQueryFn();

  return useQuery({
    queryFn: () =>
      queryFn(request).catch(
        request.catch ?? ((error) => Promise.reject(error))
      ),
    ...request.options,
    queryKey: [request.path, ...(request.options?.queryKey ?? [])],
    retry: (failureCount, error) => {
      console.error(error?.stack || error);

      if (axios.isAxiosError(error) && error.response?.status === 500) {
        return failureCount < 3;
      }
      return false;
    },
  });
};

export const useApiMutationFn = () => {
  const history = useHistory();

  return (
    /**
     *
     * @type {{
     *     map?: (res) => T;
     *     path: string;
     *     data: any;
     *     config?: import('axios').AxiosRequestConfig
     *     toast: 
     * }}
     *
     */
    request
  ) =>
    withAuthRedirect(async () => {
      return api
        .post(request.path, request.data, {
          headers: getAuthHeaders(),
          ...request.config,
        })
        .then((res) => (request.map ? request.map(res.data) : res.data));
    }, history);
};
