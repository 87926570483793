import { createContext, useContext, useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";
import {
  useApiGetQuery,
  useApiGetQueryFn,
  useApiMutation,
  useApiMutationFn,
} from "../../../hooks/useApiQuery";
import { useMutation, useQueryClient } from "react-query";
import { useDialogControl } from "../../../components/openDialog";
import { AxiosError, CanceledError } from "axios";
import { isNetworkError } from "../../../helpers/isNetworkError";

const DEFAULT_DATE = dayjs();

export const DashboardContext = createContext();

/**
 * @returns {ReturnType<typeof useDashboardInit>}
 */
export const useDashboard = () => useContext(DashboardContext);

export const useDashboardInit = () => {
  const form = useForm({
    defaultValues: {
      selectedDate: DEFAULT_DATE,
      familyCode: 0,
    },
  });

  const familyCodeList = useApiGetQuery({
    path: "/family-group",
  });

  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const [observedOrders, setObservedOrders] = useState([]);
  const [updatedOrders, setUpdatedOrders] = useState([]);

  const acknowledgeUpdate = (id) => {
    setUpdatedOrders((data) => data.filter((data) => data.id !== id));
  };

  useEffect(() => {
    setSelectedRow(null);
  }, [form.watch("selectedDate")]);

  const query = useApiGetQueryFn();

  const lastUpdateCheck = useRef(dayjs());

  useEffect(() => {
    const updateOrders = async () => {
      if (!observedOrders.length) return;

      const data = await query({
        path: `/order/update`,
        config: {
          params: {
            id: observedOrders.map((order) => order.id),
            fromDate: lastUpdateCheck.current.format("YYYY-MM-DD HH:mm:ss"),
          },
        },
      }).catch(() => null);

      if (!data) return;
      lastUpdateCheck.current = dayjs();

      data.forEach((data) => {
        queryClient.refetchQueries([
          `/order/${data.int_historico_pedido_id_fk}`,
          data.int_historico_pedido_id_fk,
        ]);

        const updatedOrder = observedOrders.find(
          (value) => value.id === data.int_historico_pedido_id_fk
        ) || {
          id: data.int_historico_pedido_id_fk,
        };

        setUpdatedOrders((orders) => [
          updatedOrder,
          ...orders
            .filter((order) => order.id !== data.int_historico_pedido_id_fk)
            .slice(0, 5),
        ]);
      });
    };

    const interval = setInterval(updateOrders, 4000);

    return () => clearInterval(interval);
  }, [observedOrders]);

  const selectedDate = form.watch("selectedDate")?.isValid()
    ? form.watch("selectedDate").format("YYYY-MM-DD")
    : undefined;

  const getIndicator = (count) => {
    if (count < 7) {
      return "ok";
    }

    if (count < 11) {
      return "warning";
    }

    return "danger";
  };

  const getPeriodToDelivery = (int_hora_entrega_tipo) => {
    switch (int_hora_entrega_tipo) {
      case 7:
        return {
          label: "Madrugada",
          value: "dawn",
          id: 7,
        };
      case 4:
        return {
          label: "Manhã",
          value: "morning",
          id: 4,
        };
      case 5:
        return {
          label: "Tarde",
          value: "afternoon",
          id: 5,
        };
      case 8:
        return {
          label: "Comercial",
          value: "comercial",
          id: 8,
        };
      case 6:
        return {
          label: "Noite",
          value: "night",
          id: 6,
        };
    }
  };

  const formatDelivery = (delivery) => {
    let timeToDelivery = dayjs(delivery.dt_hora);

    if (delivery.tm_hora_entrega) {
      const [hour, min, sec] = delivery.tm_hora_entrega.split(":");
      timeToDelivery = timeToDelivery
        .set("hour", hour)
        .set("minute", min)
        .set("second", sec);
    } else {
    }

    const periodToDelivery = getPeriodToDelivery(
      delivery.int_hora_entrega_tipo
    );

    return {
      ids: delivery.pedido_ids,
      timeToDelivery: !periodToDelivery ? timeToDelivery : null,
      quantity: delivery.QTDE,
      periodToDelivery: periodToDelivery,
      separatedProportion: Number(delivery.percentual_concluido),
    };
  };

  /**
   * @typedef {ReturnType<typeof formatDelivery>} Delivery
   */

  const separate = (
    /**
     * @type {Delivery[]}
     */
    deliveries,
    /**
     * @type {(x:Delivery)=>boolean}
     */
    filter,
    acc
  ) => {
    const separated = deliveries.filter(filter);

    separated.forEach((delivery) => {
      acc.push({
        label:
          delivery.periodToDelivery?.label ??
          delivery.timeToDelivery.format("HH:mm"),
        count: delivery.quantity,
        proportion: delivery.separatedProportion,
        data: delivery,
        indicator: getIndicator(delivery.quantity),
      });
    });
  };

  const transformDeliveries = (
    /**
     * @type {unknown[]}
     */
    deliveries
  ) => {
    let response = deliveries.map(formatDelivery).toSorted((a, b) => {
      return a.timeToDelivery?.valueOf() - b.timeToDelivery?.valueOf();
    });
    const groupBy = response.reduce((acc, next, index) => {
      const dateKey = next.timeToDelivery
        ? dayjs(next.timeToDelivery).format("HH:mm")
        : next.periodToDelivery?.label;

      if (acc[dateKey]) {
        acc[dateKey] = {
          ...acc[dateKey],
          quantity: acc[dateKey].quantity + next.quantity,
          ids: [...acc[dateKey].ids, ...next.ids],
          separatedProportion:
            ((acc[dateKey].separatedProportion || 0) +
              (next.separatedProportion || 0)) /
            2,
        };
      } else {
        acc[dateKey] = next;
      }

      return acc;
    }, {});

    console.log(groupBy);

    response = Object.values(groupBy);

    /**
     * @type {{
     *  label: string;
     *  count: number;
     *  data: Delivery;
     *  proportion: number;
     *  indicator: string;
     * }[]}
     */
    const result = [];

    separate(
      response,
      (data) => {
        if (!data.timeToDelivery) return false;
        return data.timeToDelivery.hour() < 5;
      },
      result
    );

    separate(
      response,
      (data) => {
        return data.periodToDelivery?.value === "dawn";
      },
      result
    );

    separate(
      response,
      (data) => {
        if (!data.timeToDelivery) return false;
        return (
          data.timeToDelivery.hour() >= 5 && data.timeToDelivery.hour() < 13
        );
      },
      result
    );

    separate(
      response,
      (data) => {
        return data.periodToDelivery?.value === "morning";
      },
      result
    );

    separate(
      response,
      (data) => {
        if (!data.timeToDelivery) return false;
        return (
          data.timeToDelivery.hour() >= 13 && data.timeToDelivery.hour() < 18
        );
      },
      result
    );

    separate(
      response,
      (data) => {
        return data.periodToDelivery?.value === "afternoon";
      },
      result
    );

    separate(
      response,
      (data) => {
        return data.periodToDelivery?.value === "comercial";
      },
      result
    );

    separate(
      response,
      (data) => {
        if (!data.timeToDelivery) return false;
        return data.timeToDelivery.hour() >= 18;
      },
      result
    );

    separate(
      response,
      (data) => {
        return data.periodToDelivery?.value === "night";
      },
      result
    );

    return result;
  };

  const ordersFromDay = useRef(null);
  const [newOrders, setNewOrders] = useState([]);
  const [removedOrders, setRemovedOrders] = useState([]);

  const acknowledgeNewOrder = (id) => {
    setNewOrders((data) => data.filter((data) => data.id !== id));
  };

  useEffect(() => {
    ordersFromDay.current = null;
    setNewOrders([]);
  }, [selectedDate]);

  const checkNewOrdersRequestController = useRef(new AbortController());

  const checkNewOrders = (deliveries) => {
    if (ordersFromDay.current != null) {
      deliveries.forEach((delivery) => {
        delivery.data.ids.forEach((id) => {
          const order = ordersFromDay.current.find(
            (newOrder) => newOrder.id === id
          );
          const isNewOrder = !order;

          if (isNewOrder) {
            ordersFromDay.current.push({ id, marked: true });
            setNewOrders((newOrders) => [{ id }, ...newOrders.slice(0, 5)]);
          } else {
            order.marked = true;
          }
        });
      });

      ordersFromDay.current.forEach((order) => {
        if (!order.marked)
          setRemovedOrders((removedOrders) => [
            { id: order.id },
            ...removedOrders
              .filter((removedOrder) => removedOrder.id != order.id)
              .slice(0, 5),
          ]);

        delete order.marked;
      });
    }

    if (!ordersFromDay.current)
      ordersFromDay.current = deliveries
        .map((delivery) => delivery.data.ids)
        .flat()
        .map((id) => ({
          id,
          marked: false,
        }));
  };

  const deliveries = useApiGetQuery({
    prepare: (request) => {
      checkNewOrdersRequestController.current.abort();
      checkNewOrdersRequestController.current = new AbortController();
      request.config.signal = checkNewOrdersRequestController.current.signal;
    },
    catch: (error) => {
      console.error(error);

      if (error instanceof CanceledError) {
        return;
      }

      throw error;
    },
    map: (data) => {
      const deliveries = transformDeliveries(data);
      checkNewOrders(deliveries);

      return deliveries;
    },
    path: "/order/delivery",
    config: {
      signal: checkNewOrdersRequestController.current?.signal,
      params: {
        date: selectedDate,
      },
    },
    options: {
      queryKey: [selectedDate],
      refetchInterval: 6000,
    },
  });

  const selectedDeliveryDate =
    selectedRow?.data?.timeToDelivery?.format("YYYY/MM/DD HH:mm");

  const selectedDeliveryPeriod = selectedRow?.data?.periodToDelivery?.id;

  const formatOrderType = (type) => {
    if (type === 3) {
      return {
        label: "Pedido",
        value: "P",
      };
    }

    if (type === 2) {
      return {
        label: "Orçamento",
        value: "O",
      };
    }

    if (type === 1) {
      return {
        label: "Cancelado",
        value: "C",
      };
    }

    return {
      label: "Unknow",
      value: "U",
    };
  };

  const formatOrder = (order) => {
    return {
      id: order.pedido_id,
      orderType: formatOrderType(order.int_pedido_tipo_id_fk),
      ritzEntrega: order.bl_pedido_ritzEntrega,
      completeProportion: order.flt_percentual_separado,
    };
  };

  const invalidateCurrentListedOrder = () => {
    queryClient.invalidateQueries([
      "/order/",
      selectedDeliveryDate,
      form.watch("familyCode"),
      form.watch("selectedDate"),
      selectedDeliveryPeriod,
    ]);
  };

  const invalidateCurrentDeliveryList = () => {
    queryClient.invalidateQueries(["/order/delivery", selectedDate]);
  };

  const orders = useApiGetQuery({
    path: "/order/",
    config: {
      params: {
        date:
          selectedDeliveryDate ??
          form.watch("selectedDate")?.format("YYYY/MM/DD"),
        periodToDeliver: selectedDeliveryPeriod,
        groupFamilyId: form.watch("familyCode"),
      },
    },
    map: (
      /**@type {unknown[]} */
      data
    ) => data.map(formatOrder),
    options: {
      queryKey: [
        selectedDeliveryDate,
        form.watch("familyCode"),
        form.watch("selectedDate"),
        selectedDeliveryPeriod,
      ],
      enabled: !!selectedDeliveryDate || !!selectedDeliveryPeriod,
    },
  });

  const getOrdersGroupedByDeliveryType = () => {
    if (!orders.data) return orders;

    const data = {
      ritzEntrega: orders.data.filter((order) => order.ritzEntrega),
      other: orders.data.filter((order) => !order.ritzEntrega),
    };

    return { ...orders, data };
  };

  /**
   * @typedef {{
   * id: data.int_item_id_pk,
   *   productName: string,
   *   familyGroupName: string,
   *   familyGroupId: number,
   *   location: string,
   *   separatedQuantity: number,
   *   quantity: number
   * }} OrderData
   */

  const formatOrderItem = (items) =>
    items.reduce((acc, next) => {
      if (!acc[next.familyGroupId]) {
        acc[next.familyGroupId] = {
          label: next.familyGroupName,
          data: [],
        };
      }

      acc[next.familyGroupId].data.push(next);
      return acc;
    }, {});

  const calculateOrderStats = (items) => {
    const totalNonDeletedProductsCount = items.reduce((acc, next) => {
      if (!next.deleted) {
        return acc + 1;
      }

      return acc;
    }, 0);

    const separatedCount = items.reduce((acc, next) => {
      if (!next.deleted && next.separatedQuantity == next.quantity) {
        return acc + 1;
      }

      return acc;
    }, 0);

    const warningCount = items?.reduce((acc, next) => {
      if (getItemProblem(next)) {
        return acc + 1;
      }

      return acc;
    }, 0);

    const notSeparatedCount =
      totalNonDeletedProductsCount - separatedCount - warningCount;

    const totalForStatistics =
      separatedCount + warningCount + notSeparatedCount;

    return {
      separatedProportion: separatedCount / totalForStatistics,
      problemsProportion: warningCount / totalForStatistics,
      notSeparatedProportion: notSeparatedCount / totalForStatistics,
      warningCount,
      totalNonDeletedProductsCount,
    };
  };

  const formatOrderData = (res) => {
    const items = res.items.map((data) => ({
      id: data.int_item_id_pk,
      productName: data.str_produto_descricao,
      familyGroupName: data.str_grupo_familia_descricao,
      familyGroupId: data.int_grupo_familia_id_pk,
      location: data.str_produto_localizacaoestoque,
      partnerName: data.str_parceiro_fantasia,
      partnerId: data.int_parceiro_id_pk,
      separatedQuantity: data.int_item_quantidade_separa,
      quantity: data.int_item_quantidade,
      deleted: data.status_del,
      employeeFromLastAlteration: data.str_funcionario_nome,
    }));

    return {
      id: res.int_pedido_id_pk,
      exit: dayjs(res.dt_pedido_saida),
      return: dayjs(res.dt_pedido_retorno),
      attendant: res.str_funcionario_nome,
      partner: res.str_parceiro_fantasia,
      ...calculateOrderStats(items),
      deliveryTime: res.tm_hora_entrega,
      itemsList: items,
      deletedItemList: items.filter((item) => item.deleted),
      notes: res.str_pedido_observacao_logistica,
      items: formatOrderItem(items),
      responseData: res,
    };
  };

  const queryClient = useQueryClient();
  const apiMutation = useApiMutationFn();

  const mutationQueue = useRef({});

  const addToMutationQueue = (itemId, callback) => {
    window.removeEventListener("online", mutationQueue.current[itemId]);
    mutationQueue.current[itemId] = callback;

    window.addEventListener("online", callback, {
      once: true,
    });
  };

  const separateItemFn = async ({ row, quantity }) => {
    let selectedOrderId = selectedOrder?.id;

    const invalidateQuery = async () => {
      await queryClient.invalidateQueries([
        `/order/${selectedOrderId}`,
        selectedOrderId,
      ]);
    };

    try {
      await apiMutation({
        path: `/order/item/${row.id}/separate/`,
        data: {
          quantity: quantity,
        },
      });
    } catch (error) {
      if (isNetworkError(error)) {
        addToMutationQueue(row.id, () => {
          return separateItemFn({ row, quantity }).catch(invalidateQuery);
        });

        const queryData = queryClient.getQueryData([
          `/order/${selectedOrderId}`,
          selectedOrderId,
        ]);

        const newItemList = queryData.itemsList.map((item) => {
          if (item.id == row.id)
            return {
              ...item,
              separatedQuantity: quantity,
            };

          return item;
        });

        return queryClient.setQueryData(
          [`/order/${selectedOrder?.id}`, selectedOrder?.id],
          {
            ...queryData,
            itemsList: newItemList,
            items: formatOrderItem(newItemList),
            ...calculateOrderStats(newItemList),
          }
        );
      } else throw error;
    }

    await invalidateQuery();
  };

  const checkItem = useMutation({
    mutationFn: async (row) => {
      return separateItemFn({ row, quantity: row.quantity });
    },
  });

  const separateItem = useMutation({
    mutationFn: separateItemFn,
  });
  const orderData = useApiGetQuery({
    path: `/order/${selectedOrder?.id}`,
    map: (data) => {
      setObservedOrders((orders) => [
        ...orders.filter((value) => value.id != selectedOrder?.id).slice(0, 9),
        selectedOrder,
      ]);

      return formatOrderData(data);
    },
    options: {
      enabled: !!selectedOrder,
      queryKey: [selectedOrder?.id],
      // refetchInterval: 5000,
      refetchOnReconnect: false,
    },
  });

  const getItemProblem = (item) => {
    const notFullySeparated =
      item.separatedQuantity != null &&
      item.separatedQuantity != 0 &&
      item.separatedQuantity < item.quantity;

    const isDeletedItem = item.deleted;

    if (isDeletedItem && item.separatedQuantity != 0)
      return "deletedAndSeparated";

    if (notFullySeparated) return "notFullySeparated";
  };

  return {
    form,
    familyCodeList,
    deliveries,
    orders,
    selectedRow,
    setSelectedRow,
    selectedOrder,
    setSelectedOrder,
    orderData,
    separateItem,
    checkItem,
    updatedOrders,
    acknowledgeUpdate,
    newOrders,
    acknowledgeNewOrder,
    getItemProblem,
    observedOrders,
    getOrdersGroupedByDeliveryType,
    invalidateCurrentListedOrder,
    invalidateCurrentDeliveryList,
    removedOrders,
  };
};
