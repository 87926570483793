import axios from "axios";
import { redirect } from "react-router-dom";

export const getToken = () => localStorage.getItem("authToken");

export const getAuthHeaders = () => ({
  Authorization: `Bearer ${getToken()}`,
});

export const withAuthRedirect = async (fetchFn, history) => {
  try {
    return await fetchFn();
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const statusCode = error.response.status;

      if (statusCode === 401 || statusCode === 403) {
        history.push("/login");
      }
    }

    return Promise.reject(error);
  }
};
