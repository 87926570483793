import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  IconButton,
  Link,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import React, { Fragment, useRef } from "react";
import { DashboardContext, useDashboardInit } from "./hooks/useDashboard";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Controller } from "react-hook-form";
import { Formater } from "../../hooks/formater";

import { DashboardModal } from "./DashboardModal";
import chroma from "chroma-js";

const Dashboard = () => {
  const dashboard = useDashboardInit();
  const selectedRowContainerRef = useRef();

  const ordersGroupedByDeliveryType =
    dashboard.getOrdersGroupedByDeliveryType();

  return (
    <DashboardContext.Provider value={dashboard}>
      <DashboardModal
        open={!!dashboard.selectedOrder}
        onClose={() => {
          dashboard.invalidateCurrentDeliveryList();
          dashboard.invalidateCurrentListedOrder();
          dashboard.setSelectedOrder(undefined);
        }}
      ></DashboardModal>
      <Box
        sx={{
          padding: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <FormControl
          size="small"
          sx={{
            width: "100%",
            maxWidth: 200,
            marginBottom: 2,
          }}
        >
          <Controller
            name="selectedDate"
            control={dashboard.form.control}
            render={({ field }) => (
              <DatePicker
                label="Selecione uma data"
                value={field.value}
                onChange={(newValue) => field.onChange(newValue)}
                textField={(params) => <TextField {...params} />}
              />
            )}
          />
        </FormControl>
        <FormControl
          size="small"
          sx={{
            width: "100%",
            maxWidth: 500,
            marginBottom: 2,
          }}
        >
          {dashboard.familyCodeList.isLoading ? (
            <Skeleton variant="rectangular" color="primary" height={40} />
          ) : (
            <Controller
              name="familyCode"
              control={dashboard.form.control}
              render={({ field }) => (
                <Select {...field}>
                  <MenuItem value={0} key={0}>
                    Todas as áreas
                  </MenuItem>
                  {dashboard.familyCodeList.data?.map((row) => (
                    <MenuItem
                      value={row.int_grupo_familia_id_pk}
                      key={row.int_grupo_familia_id_pk}
                    >
                      {row.str_grupo_familia_descricao}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          )}
        </FormControl>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-around",
            gap: 2,
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              padding: 1,
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <TableContainer
              component={Paper}
              sx={{
                maxWidth: 500,
                minHeight: 320,
              }}
            >
              {dashboard.deliveries.isLoading ? (
                <Skeleton variant="rectangular" color="primary" height={320} />
              ) : (
                <Table stickyHeader aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">
                        {dashboard.form
                          .watch("selectedDate")
                          .format("DD/MM/YYYY")}
                      </TableCell>
                      <TableCell align="center">#</TableCell>
                      <TableCell align="center">%</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(dashboard.deliveries.data || []).map((row) => (
                      <TableRow
                        key={row.label}
                        onClick={() => {
                          dashboard.setSelectedRow(row);
                          selectedRowContainerRef.current?.scrollIntoView();
                        }}
                        sx={{
                          cursor: "pointer",
                        }}
                      >
                        <TableCell
                          align="center"
                          sx={{
                            fontSize: 24,
                            lineHeight: 0.8,
                          }}
                        >
                          {row.label}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={
                            row.indicator == "ok"
                              ? {}
                              : {
                                  backgroundColor: (theme) =>
                                    theme.palette[row.indicator].main,
                                }
                          }
                        >
                          <Link
                            sx={{
                              fontSize: 24,
                              lineHeight: 0.8,
                              cursor: "pointer",
                              color:
                                row.indicator == "ok"
                                  ? "primary"
                                  : (theme) =>
                                      theme.palette[row.indicator].contrastText,
                              textDecorationColor:
                                row.indicator == "ok"
                                  ? "primary"
                                  : (theme) =>
                                      theme.palette[row.indicator].contrastText,
                            }}
                          >
                            {Formater.number.format(row.count)}
                          </Link>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            background: (theme) => {
                              return chroma
                                .scale([
                                  theme.palette.background.default,
                                  alpha(theme.palette.error.main, 0.25),
                                  alpha(theme.palette.success.main, 0.25),
                                ])(row.proportion ?? 0)
                                .toString();
                            },
                            fontSize: 24,
                            lineHeight: 0.8,
                          }}
                        >
                          {Formater.percent.format(row.proportion)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </TableContainer>

            <Box
              sx={{
                maxWidth: 500,
                width: "100%",
              }}
            >
              {!!dashboard.newOrders?.length && (
                <Box
                  sx={{
                    marginTop: 2,
                  }}
                >
                  <Typography>Pedidos novos</Typography>
                  <Stack direction="row" alignItems={"center"} spacing={1}>
                    {dashboard.newOrders.map((data) => (
                      <Link
                        key={data.id}
                        onClick={() => {
                          dashboard.acknowledgeNewOrder(data.id);
                          dashboard.setSelectedOrder(data);
                        }}
                        sx={{
                          cursor: "pointer",
                        }}
                      >
                        <Chip label={data.id} color="primary" />
                      </Link>
                    ))}
                  </Stack>
                </Box>
              )}
              {!!dashboard.updatedOrders?.length && (
                <Box
                  sx={{
                    marginTop: 2,
                  }}
                >
                  <Typography>Pedidos alterados</Typography>
                  <Stack direction="row" alignItems={"center"} spacing={1}>
                    {dashboard.updatedOrders.map((data) => (
                      <Link
                        key={data.id}
                        onClick={() => {
                          dashboard.acknowledgeUpdate(data.id);
                          dashboard.setSelectedOrder(data);
                        }}
                        sx={{
                          cursor: "pointer",
                        }}
                      >
                        <Chip label={data.id} color="primary" />
                      </Link>
                    ))}
                  </Stack>
                </Box>
              )}

              {!!dashboard.removedOrders?.length && (
                <Box
                  sx={{
                    marginTop: 2,
                  }}
                >
                  <Typography>Pedidos Removidos</Typography>
                  <Stack direction="row" alignItems={"center"} spacing={1}>
                    {dashboard.removedOrders.map((data) => (
                      <Link
                        key={data.id}
                        onClick={() => {
                          dashboard.acknowledgeUpdate(data.id);
                          dashboard.setSelectedOrder(data);
                        }}
                        sx={{
                          cursor: "pointer",
                        }}
                      >
                        <Chip label={data.id} color="primary" />
                      </Link>
                    ))}
                  </Stack>
                </Box>
              )}
            </Box>
          </Box>

          {!!dashboard.selectedRow && (
            <Box
              ref={selectedRowContainerRef}
              sx={{
                flex: 1,
                maxWidth: "700px",
                paddingBottom: 2,
              }}
            >
              <Typography variant="h6">
                SEPARAÇÃO:{" "}
                {dashboard.selectedRow.data?.timeToDelivery?.format(
                  "DD/MM/YYYY hh:mm:ss"
                ) ||
                  `${dashboard.form
                    .watch("selectedDate")
                    .format("DD/MM/YYYY")} ${dashboard.selectedRow.label}`}
              </Typography>
              <br />

              <Divider aria-hidden="true" />
              {ordersGroupedByDeliveryType.isLoading ? (
                <Skeleton
                  variant="rectangular"
                  color="primary"
                  sx={{
                    marginTop: 1,
                  }}
                  height={32}
                />
              ) : (
                !!ordersGroupedByDeliveryType.data &&
                Object.entries(ordersGroupedByDeliveryType.data).map(
                  ([key, groupedData]) =>
                    groupedData.length > 0 ? (
                      <Fragment key={key}>
                        <Typography
                          variant="h6"
                          sx={{
                            fontWeight: "bold",
                            marginTop: 2,
                          }}
                        >
                          {key === "ritzEntrega" && "Ritz Entrega"}
                          {key === "other" && "Cliente Retira"}
                        </Typography>

                        <Stack
                          spacing={{ xs: 1, sm: 2 }}
                          direction="row"
                          useFlexGap
                          sx={{
                            marginTop: 1,
                            flexWrap: "wrap",
                            minWidth: "235px",
                          }}
                        >
                          {groupedData.map((data) => (
                            <Link
                              key={data.id}
                              onClick={() => {
                                dashboard.setSelectedOrder(data);
                              }}
                              sx={{
                                cursor: "pointer",
                              }}
                            >
                              <Chip
                                label={
                                  <>
                                    {data.id}
                                    <Box
                                      sx={{
                                        display: "inline",
                                        marginLeft: 1,
                                        backgroundColor:
                                          data.orderType.value === "O"
                                            ? (theme) =>
                                                theme.palette.warning.main
                                            : undefined,
                                        borderRadius: "100%",
                                        paddingX: 0.5,
                                      }}
                                    >
                                      {data.orderType.value}
                                    </Box>
                                  </>
                                }
                                sx={{
                                  outlineOffset: 2,
                                  outline: dashboard.observedOrders.find(
                                    (order) => order.id === data.id
                                  )
                                    ? (theme) =>
                                        `${theme.palette.primary.main} solid 2px`
                                    : undefined,
                                  background: (theme) => {
                                    if (data.completeProportion == 1)
                                      return alpha(
                                        theme.palette.success.main,
                                        0.25
                                      );

                                    return chroma
                                      .scale([
                                        alpha(theme.palette.error.main, 0.25),
                                        alpha(theme.palette.warning.main, 0.25),
                                      ])(data.completeProportion ?? 0)
                                      .toString();
                                  },
                                }}
                              />
                            </Link>
                          ))}
                        </Stack>
                      </Fragment>
                    ) : (
                      <></>
                    )
                )
              )}
            </Box>
          )}
        </Box>
      </Box>
    </DashboardContext.Provider>
  );
};

export default Dashboard;
