export class Formater {
  static locale = "pt-BR";

  static percent = new Intl.NumberFormat(this.locale, {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  static number = new Intl.NumberFormat(this.locale, {
    maximumFractionDigits: 2,
  });
}
