import { useRef, useState } from "react";

export const useDialogControl = () => {
  const { current: eventTarget } = useRef(new EventTarget());
  const [isOpened, setOpen] = useState(false);

  const open = () => {
    setOpen(true);
    return new Promise((resolve, reject) => {
      eventTarget.addEventListener(
        "change",
        (e) => {
          setOpen(false);
          if (e.detail.reject) return reject(e.detail.response);

          resolve(e.detail.response);
        },
        {
          once: true,
        }
      );
    });
  };

  const select = (response) => {
    eventTarget.dispatchEvent(
      new CustomEvent("change", {
        detail: {
          response,
        },
      })
    );
  };

  const reject = (response) => {
    eventTarget.dispatchEvent(
      new CustomEvent("change", {
        detail: {
          reject: true,
          response,
        },
      })
    );
  };

  return {
    isOpened,
    open,
    select,
    reject,
  };
};
