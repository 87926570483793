import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import './Header.css';

function Header() {
    const history = useHistory();

    const handleLogout = () => {
        // Remover o token ou qualquer dado de autenticação
        localStorage.removeItem('authToken');
        // Redirecionar para a página de login
        history.push('/login');
    };

    return (
        <header className="header">
            <nav className="nav-menu">
                <Link to="/" style={{ fontSize: 0 }}>
                <img
                    alt="Logo"
                    loading="lazy"
                    width="97"
                    height="37"
                    decoding="async"
                    className="header__logo"
                    src="/logo.svg"
                />
                </Link>
                <ul>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/dashboard">Separação</Link></li>
                    <li>
                        <a href="https://app.ewti.com.br" target="_blank" rel="noopener noreferrer">
                            Pedido Rápido
                        </a>
                    </li>
                </ul>
                <button className="logout-button" onClick={handleLogout}>Logout</button>
            </nav>
        </header>
    );
}

export default Header;
