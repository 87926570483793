import { createTheme, ThemeProvider } from "@mui/material";

const theme = createTheme({
  palette: {
    common: {
      black: "#333",
    },
    primary: {
      main: "#282e65",
      contrastText: "#fff",
    },
    background: {
      paper: "white",
    },
    danger: {
      main: "#d32f2f",
      contrastText: "#fff",
    },
    
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: "#333", // Usa a cor primária (preto) como fundo
          color: "#fff", // Usa a cor do texto primário (branco),
          borderBottom: "none",
        },
      },
    },
  },
});

export const AppThemeProvider = (props) => {
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};
