import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Login from './pages/Login/Login';
import Dashboard from './pages/Dashboard/Dashboard';
import Page from './pages/Page/Page';
import App from './App';

const Routes = () => {
    // return (
    //     <Router>
    //         <Switch>
    //             <Route path="/" exact component={Login} />
    //             <Route path="/dashboard" component={Dashboard} />
    //             <Route path="/page" component={Page} />
    //         </Switch>
    //     </Router>
    // );
    return (
      <Router>
          <Switch>
              <Route path="/" component={App} />
          </Switch>
      </Router>
  );
};

export default Routes;
