import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Input,
  Paper,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDashboard } from "./hooks/useDashboard";
import { useForm } from "react-hook-form";
import { Formater } from "../../hooks/formater";
import { Loader, ModalLoader } from "../../components/Loader";
import CloseIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/CheckCircle";
import CircleIcon from "@mui/icons-material/Circle";
import { useDialogControl } from "../../components/openDialog";
import { useMutation } from "react-query";
import { AppDialog, DialogHeader } from "../../components/Dialog";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { alpha } from "@mui/material/styles";
import { PieChart, ChartsLegend, ChartContainer } from "@mui/x-charts";
import { useTheme } from "@emotion/react";
import { toast } from "react-toastify";

const Legend = (props) => (
  <Box
    sx={{
      display: "flex",
      gap: 0.5,
      alignItems: "center",
    }}
  >
    <CircleIcon fontSize="5" color={props.color}></CircleIcon>
    <Typography variant="caption">{props.children}</Typography>
  </Box>
);

export const DashboardModal = (props) => {
  const dashboard = useDashboard();
  const separateDialog = useDialogControl();

  const form = useForm({
    defaultValues: {
      quantity: 0,
    },
  });

  const theme = useTheme();

  return (
    <>
      <Dialog
        open={separateDialog.isOpened}
        onTransitionEnter={() => {
          form.setFocus("quantity");
        }}
        onClose={() => {
          separateDialog.reject();
        }}
      >
        <DialogContent>
          <form
            onSubmit={form.handleSubmit((data) =>
              separateDialog.select({ quantity: data.quantity })
            )}
          >
            <Box
              sx={{
                display: "flex",
                gap: 2,
              }}
            >
              <Input
                {...form.register("quantity", {
                  min: 0,
                  valueAsNumber: true,
                })}
              ></Input>
              <Button type="submit">Separar</Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>

      <AppDialog
        open={props.open}
        onClose={props.onClose}
        PaperProps={{
          sx: {
            maxWidth: "100vw",
            margin: 1,
          },
        }}
      >
        <DialogContent>
          <ModalLoader query={dashboard.orderData}>
            <>
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  lineHeight: 1,
                  height: 150,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    lineHeight: 1,
                  }}
                >
                  <Typography variant="caption" style={{ lineHeight: 1.3 }}>
                    <strong>Pedido:</strong> {dashboard.orderData.data?.id}
                  </Typography>
                  <Typography variant="caption" style={{ lineHeight: 1.3 }}>
                    <strong>Saída:</strong>{" "}
                    {dashboard.orderData.data?.exit.format("DD/MM/YYYY")}
                  </Typography>
                  <Typography variant="caption" style={{ lineHeight: 1.3 }}>
                    <strong>Retorno:</strong>{" "}
                    {dashboard.orderData.data?.return.format("DD/MM/YYYY")}
                  </Typography>
                  <Typography variant="caption" style={{ lineHeight: 1.3 }}>
                    <strong>Atendente:</strong>{" "}
                    {dashboard.orderData.data?.attendant}
                  </Typography>
                  <Typography variant="caption" style={{ lineHeight: 1.3 }}>
                    <strong>Cliente:</strong>{" "}
                    {dashboard.orderData.data?.partner}
                  </Typography>
                  <Typography variant="caption" style={{ lineHeight: 1.3 }}>
                    <strong>Entrega:</strong>{" "}
                    {dashboard.orderData.data?.deliveryTime}
                  </Typography>
                  <Typography variant="caption" style={{ lineHeight: 1.3 }}>
                    <strong>Total:</strong>{" "}
                    {dashboard.orderData.data?.itemsList?.length} produtos
                    unicos
                  </Typography>
                  <Typography variant="caption" style={{ lineHeight: 1.3 }}>
                    <strong>Deletados:</strong>{" "}
                    {dashboard.orderData.data?.deletedItemList.length}
                  </Typography>
                  <Typography variant="caption" style={{ lineHeight: 1.3 }}>
                    <strong>Separado:</strong>{" "}
                    {Formater.percent.format(
                      dashboard.orderData.data?.separatedProportion
                    )}
                  </Typography>
                  <Typography
                    variant="caption"
                    style={{ lineHeight: 1.3, maxWidth: 320 }}
                  >
                    {dashboard.orderData.data?.notes.slice(0, 80)}
                    {dashboard.orderData.data?.notes.length > 80 && "..."}
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{
                      color:
                        dashboard.orderData.data?.warningCount > 0
                          ? (theme) => theme.palette.danger.light
                          : undefined,
                    }}
                  >
                    <strong>Problemas:</strong>{" "}
                    {dashboard.orderData.data?.warningCount || 0}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    overflow: "hidden",
                    maxWidth: 200,
                    maxHeight: 200,
                  }}
                >
                  <Box
                    sx={{
                      maxWidth: 200,
                      maxHeight: 200,
                      overflow: "visible",
                      transform: "translateY(-50px)",
                    }}
                  >
                    <PieChart
                      slotProps={{
                        legend: { hidden: true },
                      }}
                      series={[
                        {
                          valueFormatter: ({ data }) => {
                            return Formater.percent.format(data);
                          },
                          data: [
                            {
                              id: 0,
                              value:
                                dashboard.orderData.data
                                  ?.notSeparatedProportion,
                              label: "Não separado",
                              color: theme.palette.warning.light,
                            },
                            {
                              id: 1,
                              value:
                                dashboard.orderData.data?.separatedProportion,
                              label: "Separado",
                              color: theme.palette.success.main,
                            },
                            {
                              id: 2,
                              value:
                                dashboard.orderData.data?.problemsProportion,
                              label: "Problemas",
                              color: theme.palette.error.main,
                            },
                          ],
                        },
                      ]}
                      width={250}
                      height={250}
                    />
                  </Box>
                </Box>
              </Box>
            </>

            <Box
              sx={{
                display: "flex",
                gap: 2,
                flexWrap: "wrap",
                alignItems: "center",
                justifyContent: "flex-end",
                marginBottom: "1rem",
                marginTop: "1rem",
              }}
            >
              <Legend color="warning">Não separado</Legend>
              <Legend color="success">Separado</Legend>
              <Legend color="error">Problemas</Legend>
            </Box>
            <Divider></Divider>
            <Box
              sx={{
                minHeight: "min(700px, calc(100vh - 2rem))",
                minWidth: "min(100vw, calc(600px - 4rem))",
              }}
            >
              {Object.values(dashboard.orderData.data?.items || {}).map(
                (data) => (
                  <Fragment key={data.label}>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                        alignItems: "center",
                        marginBottom: 2,
                        marginTop: 2,
                      }}
                    >
                      <Typography variant="h5">{data.label}</Typography>
                    </Box>
                    <TableContainer component={Paper}>
                      <Table stickyHeader aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="center">Produto</TableCell>
                            <TableCell align="center">Localização</TableCell>
                            <TableCell align="center">Qtde</TableCell>
                            <TableCell align="center">Qtde Separada</TableCell>
                            <TableCell align="center">Separar</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {(data.data || []).map((row) => (
                            <Fragment key={row.id}>
                              {!(
                                row.deleted ||
                                (row.deleted && row.separatedQuantity != 0)
                              ) && (
                                <Row
                                  row={row}
                                  separateDialog={separateDialog}
                                ></Row>
                              )}
                            </Fragment>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Fragment>
                )
              )}
            </Box>
          </ModalLoader>
        </DialogContent>
      </AppDialog>
    </>
  );
};

const Row = (props) => {
  const { row, separateDialog } = props;
  const dashboard = useDashboard();

  const separate = useMutation({
    mutationFn: async () => {
      let quantity;
      try {
        let response = await separateDialog.open();

        if (response.quantity > row.quantity) {
          return toast.error("Não pode separar mais que a quantidade total");
        }

        quantity = response.quantity;
      } catch (error) {
        return;
      }

      await dashboard.separateItem.mutateAsync({
        row,
        quantity,
      });
    },
  });

  const checkItem = useMutation({
    mutationFn: () => dashboard.checkItem.mutateAsync(row),
  });

  const clear = useMutation({
    mutationFn: () => {
      return dashboard.separateItem.mutateAsync({
        row,
        quantity: 0,
      });
    },
  });

  const problems = dashboard.getItemProblem(row);

  const buttonRef = useRef();

  return (
    <TableRow
      sx={{
        backgroundColor: problems
          ? (theme) => alpha(theme.palette.danger.main, 0.2)
          : undefined,
        cursor: "pointer",
      }}
      onClick={() => {
        buttonRef.current?.click();
      }}
    >
      <TableCell align="center" style={{ padding: 1, fontSize: 18 }}>
        {row.productName}
      </TableCell>
      <TableCell align="center" style={{ padding: 1 }}>
        <Typography variant="caption">{row.location}</Typography>
        {!!row.partnerId && !!row.location && <br />}
        {!!row.partnerId && (
          <Typography
            variant="caption"
            sx={{
              fontWeight: "bold",
            }}
          >
            ({row.partnerName})
          </Typography>
        )}
      </TableCell>
      <TableCell align="center" style={{ padding: 1, fontSize: 24 }}>
        {Formater.number.format(row.quantity)}
      </TableCell>

      <TableCell align="center" style={{ padding: 1, fontSize: 24 }}>
        {row.separatedQuantity
          ? Formater.number.format(row.separatedQuantity)
          : ""}
      </TableCell>

      <TableCell align="center" style={{ padding: 1 }}>
        <Loader
          loading={checkItem.isLoading || separate.isLoading || clear.isLoading}
        >
          {!row.separatedQuantity || row.separatedQuantity == 0 ? (
            <Box>
              <IconButton
                color="error"
                onClick={checkItem.mutate}
                aria-label="separar"
                sx={{ padding: 0 }}
                ref={buttonRef}
              >
                <CloseIcon
                  sx={{
                    fontSize: 40,
                  }}
                />
              </IconButton>
              {row.employeeFromLastAlteration && (
                <Typography
                  sx={{
                    fontSize: 8,
                  }}
                >
                  {`Separado por ${row.employeeFromLastAlteration}`}
                </Typography>
              )}
            </Box>
          ) : !problems ? (
            <Box>
              <IconButton
                onClick={separate.mutate}
                color="success"
                aria-label="separar"
                size="large"
                sx={{ padding: 0 }}
                ref={buttonRef}
              >
                <CheckIcon
                  sx={{
                    fontSize: 40,
                  }}
                />
              </IconButton>
              {row.employeeFromLastAlteration && (
                <Typography
                  sx={{
                    fontSize: 8,
                  }}
                >
                  {`Separado por ${row.employeeFromLastAlteration}`}
                </Typography>
              )}
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <IconButton
                onClick={clear.mutate}
                color="warning"
                aria-label="separar"
                size="large"
                sx={{ padding: 0 }}
                ref={buttonRef}
              >
                <WarningAmberIcon
                  sx={{
                    fontSize: 40,
                  }}
                />
              </IconButton>
              {row.employeeFromLastAlteration && (
                <Typography
                  sx={{
                    fontSize: 8,
                  }}
                >
                  {`Separado por ${row.employeeFromLastAlteration}`}
                </Typography>
              )}
            </Box>
          )}
        </Loader>
      </TableCell>
    </TableRow>
  );
};
