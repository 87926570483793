import { Box, CircularProgress, Typography } from "@mui/material";

/**
 * @typedef {{
 * loading: any;
 * size: any;
 * error: any;
 * errorContainerProps: any;
 * }} LoaderProps
 */
export const Loader = (
  /**
   * @type { (LoaderProps & import('@mui/material').BoxProps)}
   */
  props
) => {
  return (
    <>
      {props.loading && (
        <Box
          {...{
            ...props,
            loading: undefined,
          }}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            ...props.sx,
          }}
        >
          <CircularProgress size={props.size || 40} />
        </Box>
      )}
      {!props.loading && !props.error && props.children}
      {props.error ? (
        <Box
          {...{
            ...props,
            loading: undefined,
          }}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            ...props.sx,
          }}
        >
          <Typography color="error">
            {props.error.message || "Houve um Erro! Tente mais tarde"}
          </Typography>
        </Box>
      ) : (
        ""
      )}
    </>
  );
};

export const QueryLoader = (
  /**
   * @type {Exclude<ModalLoaderProps, 'error' | 'loading'> & {
   * query: any
   * }}
   */
  props
) => {
  return (
    <Loader
      {...props}
      loading={props.query.isLoading}
      error={!props.query.data && props.query.error}
    ></Loader>
  );
};

/**
 * @typedef {import('react').ComponentProps<typeof QueryLoader>} ModalLoaderProps
 */

export const ModalLoader = (
  /**
   * @type {ModalLoaderProps & {
   * maxWidth: string,
   * minHeight: string
   * }}
   */
  props
) => {
  return (
    <QueryLoader
      {...props}
      sx={{
        width: "100vw",
        maxWidth: props.maxWidth ?? "600px",
        minHeight: props.minHeight ?? "700px",
        ...props.sx,
      }}
    ></QueryLoader>
  );
};
