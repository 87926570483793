import React, { useState } from 'react';
import api from '../../config/api';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = async (e) => {
      console.log('in handleLogin');
      e.preventDefault();
      try {
        const response = await api.post('/auth/login', { username, password });
        if (response.data.token) {
          localStorage.setItem('authToken', response.data.token);

          window.location.href = '/dashboard';
        }
      } catch (error) {
        console.error('Login failed:', error);
      }
    };
    return (
      <main className="login-container">
          <form className="login-form" onSubmit={handleLogin}>
              <h2>Login</h2>
              <label htmlFor="username">Usuário:</label>
              <input
                  type="text"
                  id="username"
                  name="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
              />
              <label htmlFor="password">Senha:</label>
              <input
                  type="password"
                  id="password"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
              />
              <button type="submit">Login</button>
          </form>
      </main>
  );
    // return (
    //     <div>
    //         <h2>Login</h2>
    //         <form onSubmit={handleLogin}>
    //             <input 
    //                 type="email" 
    //                 placeholder="Email" 
    //                 value={email} 
    //                 onChange={(e) => setEmail(e.target.value)} 
    //             />
    //             <input 
    //                 type="password" 
    //                 placeholder="Password" 
    //                 value={password} 
    //                 onChange={(e) => setPassword(e.target.value)} 
    //             />
    //             <button type="submit">Login</button>
    //         </form>
    //     </div>
    // );
};

export default Login;
