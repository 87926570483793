import React from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import Login from "./pages/Login/Login";
import Dashboard from "./pages/Dashboard/Dashboard";
import Page from "./pages/Page/Page";
import Header from "./components/Header";
import Footer from "./components/Footer";
import PrivateRoute from "./PrivateRoute";
import "./styles/global.css";
import { Box } from "@mui/material";
import { AppThemeProvider } from "./providers/Theme";
import { ReactQueryProvider } from "./providers/ReactQuery";
import { AppLocationProvider } from "./providers/Location";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const location = useLocation();
  const isLoginPage = location.pathname === "/login";

  return (
    <AppThemeProvider>
      <ReactQueryProvider>
        <AppLocationProvider>
          <ToastContainer></ToastContainer>
          <div className="app">
            {!isLoginPage && <Header />}{" "}
            {/* Renderiza Header apenas se não for a página de login */}
            <Box
              sx={{
                paddingTop: 2,
                minHeight: "calc(100vh - 146px - 46px)",
              }}
            >
              <Switch>
                <Route path="/login" exact component={Login} />
                <PrivateRoute path="/dashboard" component={Dashboard} />
                <PrivateRoute path="/page" component={Page} />
                <PrivateRoute exact path="/" component={Dashboard} />
              </Switch>
            </Box>
            {!isLoginPage && <Footer />}{" "}
            {/* Renderiza Footer apenas se não for a página de login */}
          </div>
        </AppLocationProvider>
      </ReactQueryProvider>
    </AppThemeProvider>
  );
}

export default App;
